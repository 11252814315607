import { useContext } from "react";
import styled from "styled-components";
import { useParams, Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  Grid,
  Row,
  Tile,
} from "carbon-components-react";
import { Catalog20, RequestQuote16, UserFollow16 } from "@carbon/icons-react";

import useSWR from "swr";

import { SchemaContext } from "../../context";
import { SchemaViewer } from "../../components/Viewer";

import { CustomerViewer, BookVisitViewer } from "../../components/Viewer";
import { URL, Fetcher as F } from "../../api";

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 42px;
  > .bx--btn {
    margin-right: 32px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const BookVisitInfo = () => {
  const { bookVisitNote: bookVisitNoteSchema } =
    useContext(SchemaContext) || {};

  const { bvid } = useParams();

  const bookVisitURL = `${URL.bookVisit.list}${bvid}/?expand=centers,customer,note,service_employee,status,note.mom_medical_history,note.mom_food_allergies,deadorder_status`;
  const { data: bookVisit } = useSWR([bookVisitURL], F.withToken);
  const { customer_id, customer, note_id, note, ...rest } = bookVisit ?? {};

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/bookvisit/">預約參訪</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          瀏覽預約參訪
        </BreadcrumbItem>
      </Breadcrumb>

      <Grid>
        <Row>
          <Column>
            <Tile>瀏覽預約參訪</Tile>

            <Accordion>
              <AccordionItem title="聯絡人" open={!!customer_id}>
                <CustomerViewer loading={!bookVisit} data={customer} />
              </AccordionItem>
              <AccordionItem title="預約參訪" open>
                <BookVisitViewer loading={!bookVisit} data={rest} />
              </AccordionItem>
              <AccordionItem title="產婦相關" open={!!note_id}>
                <SchemaViewer
                  fields={bookVisitNoteSchema.fields}
                  excludes={[
                    "id",
                    "created",
                    "modified",
                    "keyin_employee_id",
                    "puerpera_id",
                  ]}
                  isLoading={!bookVisit}
                  data={note}
                />
              </AccordionItem>
            </Accordion>

            <Actions>
              <Button
                as={Link}
                to={`/bookvisit/${bvid}/edit`}
                kind="primary"
                size="small"
                renderIcon={RequestQuote16}
              >
                編輯預約參訪
              </Button>
              <Button
                as={Link}
                to={`/schedule/${bvid}/history`}
                kind="primary"
                size="small"
                renderIcon={Catalog20}
              >
                預約參訪紀錄
              </Button>
              <Button
                as={Link}
                to={`/puerpera/create?customer=${customer?.id}`}
                kind="primary"
                size="small"
                disabled={customer?.id === undefined}
                renderIcon={UserFollow16}
              >
                簽約產婦建檔
              </Button>
            </Actions>
          </Column>
        </Row>
      </Grid>
    </Content>
  );
};
