const base = window.django.urls.api;

export const URL = {
  baby: `${base}/baby/`,
  babybed: `${base}/babybed/`,
  babyStatus: `${base}/babyStatus/`,
  bookVisit: {
    list: `${base}/bv/list/`,
    note: `${base}/bv/note/`,
    schedule: `${base}/bv/schedule/`,
    status: `${base}/bv/bv_s/`,
    deadOrderStatus: `${base}/bv/bv_dead/`,
    interactionStatus: `${base}/bv/schedule_is/`,
    customer: `${base}/bv/customer/`,
    customerStatus: `${base}/bv/customer_status/`,
  },
  book: {
    activity: `${base}/book/activity`,
    room: `${base}/book/room/`,
    roomSchedule: `${base}/book/roomSchedule/`,
  },
  care: `${base}/care/`,
  center: `${base}/cc/`,
  contract: `${base}/contract/`,
  customer: `${base}/customer/`,
  department: `${base}/department/`,
  parking: `${base}/parking/`,
  puerpera: `${base}/puerpera/`,
  room: `${base}/room/`,
  roomType: `${base}/roomType/`,
  staff: `${base}/staff/`,
  changePassword: `${base}/change_password/`,
  token: `${base}/token/`,
  refresh: `${base}/token/refresh/`,
  export: {
    bookroom: `${base}/export/bookroom/`,
    uploadBookRoom: `${base}/export/uploadBookRoom`,
    housing: `${base}/export/uploadHousing`,
  },
  housing: `${base}/housing/`,
  housingEvent: `${base}/housingEvent/`,
  housingInit: `${base}/housing/init`,
  foodVendor: `${base}/foodvendor`,
  foodAllergies: `${base}/foodallergies`,
  medicalHistory: `${base}/medicalhistory`,
  summary: {
    status: `${base}/summary/babyStatusReocrdStatus`,
    aberrent: `${base}/summary/babyStatusRecordAberrent/`,
    babyStatusRecord: `${base}/summary/babyStatusRecord/`,
  },
};
