import { Component } from "react";
import * as Widget from "./Widget";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 24px;
  column-gap: 32px;
`;

export class SchemaEditor extends Component {
  constructor(props) {
    super(props);
    this._handleChange = this._handleChange.bind(this);
  }

  _handleChange(obj) {
    const { fields = [], data, onChange } = this.props;
    if (typeof onChange !== "function") return;
    const newData = {
      ...data,
      ...obj,
    };
    let result = {};
    for (let field of fields) {
      const v = newData[field.key];
      if (v !== undefined) result[field.key] = v;
    }
    onChange(result);
  }

  render() {
    const {
      id,
      className,
      style,
      fields = [],
      excludes = [],
      filter = {},
      requiredFields = [],
      data = {},
    } = this.props;
    let flds = [];
    for (let field of fields) {
      const { key, ui } = field;
      if (excludes.includes(key)) continue;
      if (ui["ui:readonly"] !== undefined && ui["ui:readonly"] === true)
        continue;
      if (requiredFields.includes(key)) {
        field.required = true;
      }
      flds.push(field);
    }

    return (
      <Container id={id} className={className} style={style}>
        {flds.map((field) => {
          const key = `${id}_${field.key}`;
          const Comp = findComponentForField(field);
          const _data = data && data[field.key];
          const rowspan = field.ui["ui:rowspan"] ?? 0;
          const colspan = field.ui["ui:colspan"] ?? 0;
          let style = {};
          if (field.ui["style"]) {
            style = field.ui["style"];
          }
          if (rowspan) {
            style["grid-row-end"] = `span ${rowspan}`;
          }
          if (colspan) {
            style[`grid-column-end`] = `span ${colspan}`;
          }
          return (
            <Comp
              key={key}
              id={key}
              style={style}
              field={field}
              filter={filter}
              data={_data}
              onChange={this._handleChange}
            />
          );
        })}
      </Container>
    );
  }
}

function findComponentForField(field) {
  let widget = field.ui["ui:widget"] ?? "input";
  switch (widget) {
    case "date-time":
      return Widget.DateTime;
    case "date":
      return Widget.Date;
    case "center":
      return Widget.Center;
    case "center-list":
      return Widget.MultiCenter;
    case "room-type":
      return Widget.RoomType;
    case "department":
      return Widget.Department;
    case "radio":
      return Widget.Radio;
    case "note":
      return Widget.Note;
    case "dropdown":
      return Widget.Enum;
    case "checkbox":
      return Widget.Checkbox;
    case "switch":
      return Widget.Switch;
    case "babybeds":
      return Widget.BabyBed;
    case "contract":
      return Widget.Contract;
    case "room":
      return Widget.Room;
    case "parking-space":
      return Widget.ParkingSpace;
    case "visit_schedule":
      return Widget.VisitSchedule;
    case "employee":
      return Widget.Employee;
    case "foodvendor":
      return Widget.FoodVendor;
    case "food-allergies":
      return Widget.FoodAllergies;
    case "medical-history":
      return Widget.MedicalHistory;
    case "visit-status":
      return Widget.VisitStatus;
    case "interaction-status":
      return Widget.ScheduleInteractionStatus;
    case "deadorder-status":
      return Widget.DeadOrderStatus;
    case "customer-visit-status":
      return Widget.CustomerBookVisitStatus;
    case "number":
      return Widget.Number;
    default:
      return Widget.Input;
  }
}
